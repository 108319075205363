@import color

mat-label
    color: #8e8e8ee2

.mat-form-field-wrapper
    padding-bottom: 0

.mat-form-field-appearance-outline .mat-form-field-infix
    border-top: 0
    padding: 1em 0
.mat-form-field-appearance-outline .mat-select-arrow-wrapper
    transform: translateY(0) !important

.mat-select-arrow
    color: $primary-button-color

.mat-form-field
    width: 100%

.mat-form-field-appearance-outline .mat-form-field-label
    margin-top: 0
    top: 2em

//Queries para mobile
@media only screen and (max-width: 376px) and (max-height: 668px)
    .mat-form-field-appearance-outline .mat-form-field-infix
        border-top: 0
        padding: 0.65em 0
    .mat-form-field-appearance-outline .mat-form-field-label
        margin-top: 0
        top: 1.65em

@media only screen and (max-width: 376px) and (min-height: 668px) and (max-height: 740px)
    .mat-form-field-appearance-outline .mat-form-field-infix
        border-top: 0
        padding: 0.8em 0
    .mat-form-field-appearance-outline .mat-form-field-label
        margin-top: 0
        top: 1.8em      

@media only screen and (min-width: 540px) and (max-width: 767px)
    .mat-form-field-appearance-outline .mat-form-field-infix
        border-top: 0
        padding: 0.8em 0
    .mat-form-field-appearance-outline .mat-form-field-label
        margin-top: 0
        top: 1.8em    